import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import SelectCountry from "./tools/SelectCountry";
import SelectDocType from "./tools/SelectDocType";
import {checkAndSetCanValidateStep} from "../HelperFunctions";
import Button from "@material-ui/core/Button";

const DocumentSubmit = props => {

    const mandatoryFields = ["country", "docType", "idDoc"];
    const [canSendFile, setCanSendFile] = useState(false);

    const onChange = (e) => {
        const newInputs = {...props.fileFormInputs};
        if (e.target.files) {
            const file = e.target.files[0];
            if (file.size >= 3000000) {
                props.fileSizeError()
            } else {
                props.onFileChange(file);
                newInputs[e.target.name] = file;
            }
        } else {
            props.onChange(e);
            newInputs[e.target.name] = e.target.value;
        }
        if (props.isTunnel) {
            checkAndSetCanValidateStep(newInputs, mandatoryFields, props.canValidateStep, props.setCanValidateStep)
        } else {
            const notValid = mandatoryFields.some(field => !newInputs[field] || newInputs[field] === "");
            if (!notValid !== canSendFile) {
                setCanSendFile(!notValid)
            }
        }
    };

    const refs = {};

    return (
        <>
            <div>
                <div className="flex flex-row flex-auto flex-shrink-0 antialiased text-gray-800">
                    <div className="bg-white p-4 mb-6">
                        <div>
                            <span className="best-title"><FormattedMessage id="label.add.document"/></span>
                            <div className="title-divider">
                                <span></span>
                            </div>
                        </div>
                        <div className="clear"/>
                        <div className="inline-block mt-2 w-full">
                            <SelectCountry onChange={onChange}/>
                        </div>
                        <div className="clear"/>
                        <div className="inline-block mt-2 w-full">
                            <SelectDocType onChange={onChange}/>
                        </div>
                        <div className="clear"/>
                        <div className="inline-block mt-2 w-full">
                            <input key="idDoc" name="idDoc" type="file" ref={ref => refs.upload = ref} onChange={onChange}/>
                        </div>
                        <div className="text-xs my-1">
                            <FormattedMessage id="label.document.size"/>
                        </div>
                        <input type="hidden" name="saleId" value={props.saleId}/>
                        <div className="clear mb-2"/>
                        {!props.isTunnel && <Button size="small" disabled={!canSendFile} onClick={props.saveDocument} color="primary" variant="contained">
                            <FormattedMessage id="common.confirm"/>
                        </Button>}
                    </div>
                </div>
            </div>
        </>
    )
};

DocumentSubmit.propTypes = {
    onChange: PropTypes.func,
    saveDocument: PropTypes.func,
    onFileChange: PropTypes.func,
    fileSizeError: PropTypes.func,
    fileFormInputs: PropTypes.func,
};

export default DocumentSubmit;