import React from "react";
import PropTypes from 'prop-types';

const classnames = 'bg-gray-200 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-2 block w-full appearance-none text-xs';

const Input = (props) => {
    return (
        <input
            id={props.id ?? props.name}
            name={props.name}
            placeholder={props.placeholder ? props.placeholder : props.name}
            type={props.type ? props.type : typeof props.defaultValue === "boolean" ? "checkbox" :"text"}
            onChange={event => props.onChange(event)}
            onKeyPress={props.onKeyPress ? props.onKeyPress : () => {}}
            defaultValue={props.defaultValue}
            checked={props.defaultValue}
            className={classnames}
            onBlur={props.onBlur ? props.onBlur : () => {}}
        />
    )
};

Input.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onKeyPress: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool])
};

export default Input;