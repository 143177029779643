
const isRecette = window.location.hostname.includes('recette');
const isMoniteur = window.location.hostname.includes('moniteur');
const domainArray = window.location.hostname.split('.');
if (domainArray.length >=3) domainArray.shift();
const domain = domainArray.join('.');

export default {
  isMoniteur,
  isRecette,
  domain,
  site: isMoniteur ? "moniteur" : "drouot",
  gingolemApi: `https://${isRecette ? 'internal-recette.zonesecure.org' : 'api.drouot.com'}/${
      isMoniteur ? 'moniteur' : 'drouot'
  }/gingolem`,
  gringottsApi: `https://${isRecette ? 'internal-recette.zonesecure.org' : 'api.drouot.com'}/payment`,
  drouotPspid: isRecette ? "DROUOTFLEXTEST" : isMoniteur ? "drouotmoniteur" : "drouotsi",
  oauthRedirect: `https://auth${
    isRecette ? `-recette` : ''}.${domain}`,
  sumsubUrl: "https://api.sumsub.com",
  uuidCookieName: "auth2_token",
  gaTagIdDrouot: "G-EVJGN3MMQP",
  gaTagIdMoniteur: "G-5F5MEBJ705",
  gtmTagIdDrouot: "GTM-K43NVSN",
  gtmTagIdMoniteur: "",
};
