
export const checkAndSetCanValidateStep = (newInputs, mandatoryFields, canValidateStep, setCanValidateStep, setHasInvalidCharacters = emptyFunction) => {
    const notValid = mandatoryFields.some(field => !newInputs[field] || newInputs[field] === "");
    const invalidValues = mandatoryFields.some(field => hasInvalidCharacters(newInputs[field]));
    if (notValid || invalidValues) {
        setHasInvalidCharacters(invalidValues);
        if (canValidateStep) setCanValidateStep(false);
        return
    }
    setHasInvalidCharacters(false);
    setCanValidateStep(true);
};

export const isUserValid = (user) => user !== null && user.attributes !== undefined && (user.attributes.email_verified || user.attributes.identities !== undefined) && user.attributes["custom:pass_id"] !== undefined;

/**
 Check the word only contains accepted characters
 Accepted = ASCII code between 32 and 253 (both included)
 */
export const hasInvalidCharacters = word => {
    if (!word || typeof word === "boolean" || typeof word === "object") return false;
    return word.split("").map(c => c.charCodeAt(0)).some(code => code < 32 || code > 253)
}

const emptyFunction = () => {}