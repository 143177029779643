import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
    },
}));

const SelectDocType = (props) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl} noValidate>
            <InputLabel shrink htmlFor="age-native-label-placeholder">
                <FormattedMessage id="label.type.document"/>
            </InputLabel>
            <NativeSelect
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={props.onChange}
                name="docType"
            >
                <FormattedMessage id='select.professional.doc.type'>
                    {(message) => <option value="" selected disabled>{message}</option>}
                </FormattedMessage>
                <FormattedMessage id='label.kbis'>
                    {(message) => <option value='kbis'>{message}</option>}
                </FormattedMessage>
                <FormattedMessage id='label.registre.commerce'>
                    {(message) => <option value='inscription au registre du commerce'>{message}</option>}
                </FormattedMessage>
            </NativeSelect>
        </FormControl>
    )
};


export default SelectDocType;