import React, {Component} from "react";
import Input from "./tools/Input";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from "react-intl";
import config from "../config";
import {Link} from "react-router-dom";
import SocialLogin from "./tools/SocialLogin";

const SignInForm = (props) => {
    const handleKeypress = e => {
        if (e.code === "Enter") {
            props.signIn();
        }
    };

    return (
        <div className="py-6">
            <div className="flex mx-auto">
                <div className="hidden lg:block lg:w-1/2 bg-gray-400 shadow-lg p-10 rounded-lg overflow-hidden">
                    <div className={`${config.isMoniteur ? "bg-moniteur" : "bg-drouot-1"} bg-no-repeat bg-contain max-w-sm md:max-w-lg h-60 mb-8`}/>
                    <h2 className="text-white text-3xl font-semibold"><FormattedMessage id="info.nouvel.utilisateur"/></h2>
                    <div className="text-white text-xl mt-2 font-semibold">
                        <FormattedMessage id="info.inscrivezvous.gratuit"/>
                    </div>
                    <p className="xs:hidden text-white text-md mt-2">
                        <FormattedMessage id="info.vivre.experience" values={{site: config.isMoniteur ? "Moniteur Live" : "Drouot"}}/>
                    </p>
                    <Link to="/sign-up">
                        <button className="bg-gray-700 text-white text-uppercase px-6 py-2 rounded font-weight-bold hover:bg-gray-800 transition">
                            <FontAwesomeIcon icon={faUserCircle} className="mr-2" />
                            <FormattedMessage id="common.create.account"/>
                        </button>
                    </Link>
                </div>
                <div className="w-full p-8 lg:w-1/2 bg-white shadow-lg p-8 rounded-lg overflow-hidden">

                    <SocialLogin />

                    <div className="text-center mb-3">
                        <h6 className="text-blueGray-500 text-sm font-bold"><FormattedMessage id="common.connect.with.email"/></h6>
                    </div>

                    <h2 className="text-2xl font-bold text-center"><FormattedMessage id="mypass.connexion"/></h2>
                    <div className="mt-4">
                        <label className="block text-sm font-bold mb-2"><FormattedMessage id="common.email"/></label>
                        <Input onChange={props.onChange} onKeyPress={handleKeypress} type="email" name="email" label={<FormattedMessage id="label.email"/>}/>
                    </div>
                    <div className="mt-4">
                        <div className="flex justify-between">
                            <label className="block text-sm font-bold mb-2"><FormattedMessage id="common.password"/></label>
                            <Link to="/forgot-password"><button className="text-xs text-gray-500"><FormattedMessage id="common.forget.password"/></button></Link>
                        </div>
                        <Input onChange={props.onChange} onKeyPress={handleKeypress} type="password" name="password" label={<FormattedMessage id="label.psw"/>}/>
                    </div>
                    <div className="mt-8">
                        <button onClick={props.signIn} className="w-full bg-red-600 text-white text-center text-uppercase px-8 py-3 rounded font-weight-bold hover:bg-red-800 transition">
                            <FormattedMessage id="mypass.connexion"/>
                        </button>
                    </div>
                    <div className="mt-4 flex items-center justify-between">
                        <span className="border-b w-1/5 md:w-1/4"/>
                        <Link to="/sign-up"><button className="text-xs text-gray-500 uppercase"><FormattedMessage id="common.or.create.account"/></button></Link>
                        <span className="border-b w-1/5 md:w-1/4"/>
                    </div>
                </div>
            </div>
        </div>
    )
};

SignInForm.propTypes = {
    onChange: PropTypes.func,
    signIn: PropTypes.func,
};

export default SignInForm