import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import {
    continueAsClick,
    saveProfileRequest,
    selectCurrentUserProfile,
    selectIsFederatedUser,
    selectUser,
    signOut,
} from "../reducers/authSlice";
import {
    cgvStep,
    confirmMailStep,
    confirmPhoneStep,
    GreenPointDocStep,
    infoReminderStep,
    personalInfoStep,
    sumsubStep
} from "../components/nav/StepsData";
import {resetTunnel, setSteps} from "../reducers/tunnelSlice";
import {hasNonRejectedDocs, isGoneThroughSumsub} from "../Passeport";
import {isUserValid} from "../HelperFunctions";
import {FormattedMessage} from "react-intl";
import SaleDate from "../components/tools/SaleDate";
import HeaderLight from "../components/global/HeaderLight";
import NavStepVerContainer from "./NavStepVerContainer";
import Header from "../components/global/Header";
import {
    cgvError,
    createCgvForSaleRequest,
    getCgvForSaleRequest,
    selectCgvForSale,
    selectInfoForSale
} from "../reducers/cgvSlice";
import SaleTunnelWrapper from "../components/SaleTunnelWrapper";

const CgvContainer = (props) => {

    const initialUserRef = useRef();
    const saleId = parseInt(useParams().saleId);
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const userProfile = useSelector(selectCurrentUserProfile);
    const isFederatedUser = useSelector(selectIsFederatedUser);
    const saleInfo = useSelector((state) => selectInfoForSale(state, saleId));
    const cgv = useSelector((state) => selectCgvForSale(state, saleId));

    useEffect(() => {
        dispatch(resetTunnel());
        if (isNaN(saleId)) {
            dispatch(cgvError("a correct sale must be provided"));
        } else {
            dispatch(getCgvForSaleRequest(saleId));
        }
    }, []);

    useEffect(() => {
        addRequiredStep();
    }, [saleInfo]);


    const addRequiredStep = () => {
        const newSteps = [];
        if (saleInfo) {
            if (!isUserValid(user)) {
                if ((!user || !user.attributes.email_verified) && !isFederatedUser) {
                    newSteps.push(confirmMailStep());
                }
                if (!user || !user.attributes["custom:pass_id"]) {
                    newSteps.push(personalInfoStep());
                    newSteps.push(cgvStep(saleId, saleInfo.conditions, saleInfo.cpName, false))
                } else {
                    newSteps.push(infoReminderStep(saleId, userProfile, user.attributes.email, saleInfo.conditions, saleInfo.cpName, saleInfo.cpType, () => dispatch(saveProfileRequest()), false));
                }
            } else {
                newSteps.push(infoReminderStep(saleId, userProfile, user.attributes.email, saleInfo.conditions, saleInfo.cpName, saleInfo.cpType, () => dispatch(saveProfileRequest()), false));
            }
            if (!userProfile.phoneConfirmed) {
                newSteps.push(confirmPhoneStep());
            }
            if (!isGoneThroughSumsub(userProfile)) newSteps.push(sumsubStep());
            if (!hasNonRejectedDocs(userProfile)) newSteps.push(GreenPointDocStep(saleId));
            dispatch(setSteps(newSteps));
        }
    };


    return (
        <>
            {!cgv ? <HeaderLight/> : <Header signOut={() => dispatch(signOut())}/>}
            {saleInfo &&
                <SaleTunnelWrapper titleLabel={"info.cgv.conditions"} saleInfo={saleInfo}>
                    {!cgv &&
                    <NavStepVerContainer
                        lastStepLabel={"common.next"}
                        updateForm={props.updateForm}
                        endAction={() => createCgvForSaleRequest(saleId)}
                    />}
                    {cgv &&
                    dispatch(continueAsClick())}
                </SaleTunnelWrapper>
            }
        </>
    );
};
export default CgvContainer

