import { call, put, takeLatest, select, throttle } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Amplify, { Auth } from 'aws-amplify';
import { REHYDRATE } from "redux-persist";
import {
    restoreDefault,
    signInRequest,
    signInSuccess,
    refreshUserProfile,
    updateUserProfile,
    signUpRequest,
    signUpSuccess,
    confirmSignUpRequest,
    resendConfirmationMail,
    requestConfirmationCodeSMS,
    requestConfirmationCodeSMSSuccess,
    confirmPhoneRequest,
    confirmPhoneSuccess,
    saveProfileRequest,
    saveProfileSuccess,
    updateSingleUserField,
    forgotPasswordRequest,
    changePasswordRequest,
    changePasswordSuccess,
    confirmForgotPasswordRequest,
    confirmForgotPasswordSuccess,
    signOut,
    continueAsClick,
    setLoading,
    authError,
    selectFormInputs,
    selectUser,
    requestSumSubToken,
    requestSumSubTokenSuccess,
    updateUserApplicantIdRequest,
    selectFingerprint,
    updateFingerprintRequest,
    updateFingerprintSuccess,
} from "../reducers/authSlice";
import {
    defaultLocation,
    resetUserPath,
    setUserPath,
    selectRedirUri,
    selectInitialLocation,
} from "../reducers/navigationSlice";
import {
    nextActiveStep,
    resetTunnel, stepSucceeded,
} from "../reducers/tunnelSlice";
import awsConfig from "../aws-config";
import config from "../config";
import {dispatchErrorAccordingToStatus, getOrPostToAuthenticatedRoute} from "./helper";
import { isUserValid } from "../HelperFunctions";

Amplify.configure(config.isMoniteur ? awsConfig.awsConfigMoniteur : awsConfig.awsConfigDrouot );

// checks if route can act as sign up tunnel ex: /registration route can sign up user + create registration
const isTunnelingRoute = (path) => path.includes('registration');

function* initSaga() {
    const initialLocation = yield select(state=> state.router.location);
    const searchParams = new URLSearchParams(window.location.search);
    const redirUri = searchParams.get("redirUrl");
    searchParams.delete("redirUrl");
    let url = initialLocation.pathname;
    const query = searchParams.toString();
    if (query) {
        url = `${url}?${query}`;
    }
    const userPath = {
        redirUri:redirUri,
        initialLocation: url,
    };
    try {
        // https://stackoverflow.com/questions/58397875/reference-to-this-is-null-in-function-called-by-redux-saga-call
        // Auth is part of the context and should not be lost while using call api hence the strange way it's written
        // if not logged in DD we want to make sure to bypass cache and refresh session to check that tokens are not invalidated, if logged in, we can use cache to avoid useless direct queries to cognito
        const isLoggedInDD = getCookie("refresh");
        const currentUser = yield call([Auth, Auth.currentAuthenticatedUser], {bypassCache: !isLoggedInDD});
        if (currentUser && currentUser.getSignInUserSession()) {

            const isFederated = currentUser.attributes && currentUser.attributes.identities;
            if (isFederated) {
                const persistedStore = {
                    initialLocation: yield select(selectInitialLocation),
                    redirUri: yield select(selectRedirUri),
                };
                userPath.redirUri = userPath.redirUri || persistedStore.redirUri;
                userPath.initialLocation = userPath.initialLocation !== defaultLocation ? userPath.initialLocation : persistedStore.initialLocation;
            }

            // we don't want to confuse user if not logged in DD
            //very annoying for local dev so let's check env before knowing if we redirect to dd
            if (process.env.NODE_ENV === 'production' && isUserValid(currentUser) && userPath.initialLocation === defaultLocation) {
                yield put(setLoading());
                yield put(resetUserPath());
                refreshSessionAndSetCookies(currentUser, true, userPath.redirUri);
                return
            } else {
                refreshSessionAndSetCookies(currentUser);
                yield put(signInSuccess({currentUser}));
                if (isUserValid(currentUser)) {
                    yield put(push(userPath.initialLocation));
                    userPath.initialLocation = defaultLocation;
                } else {
                    if (!isTunnelingRoute(userPath.initialLocation)) {
                        yield put(push('/sign-up/confirm'))
                    } else {
                        yield put(push(userPath.initialLocation));
                        userPath.initialLocation = null;
                    }
                }
            }
        }
    } catch (error) {
        console.log({error});
        // no need to push error in redux state because it's likely just because user is not authenticated yet
    }
    yield put(setUserPath(userPath));
}

function* loadProfileSaga() {
    const user = yield select(selectUser);
    if (user && isUserValid(user)) {
        try {
            const resp = yield call(getOrPostToAuthenticatedRoute, user, "/users");
            if (resp.status !== 200) {
                yield put(authError(resp.statusText))
            } else {
                const data = yield resp.json();
                yield put(updateUserProfile(data))
            }
        } catch (e) {
            console.log({e});
            yield put(authError(e.toString()));
        }
    } else {
        // it's not a realistic error but it needs to trigger an action to stop loading
        yield put(authError(null));
    }
}

function* signInSaga() {
    const data = yield select(selectFormInputs);
    const initialLocation = yield select(selectInitialLocation);
    const redirUri = yield select(selectRedirUri);
    try {
        let currentUser = yield call([Auth, Auth.signIn], data.email, data.password, {email: data.email});
        if (currentUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
            yield call([Auth, Auth.completeNewPassword], currentUser, data.password, {email: data.email});
            currentUser = yield call([Auth, Auth.signIn], data.email, data.password, {email: data.email});
        }
        if (isUserValid(currentUser) && initialLocation === defaultLocation) {
            yield put(setLoading());
            yield put(resetUserPath());
            refreshSessionAndSetCookies(currentUser, true, redirUri);
            return
        }

        yield put(signInSuccess({currentUser}));
        refreshSessionAndSetCookies(currentUser);
        if (!isUserValid(currentUser)){
            if (!isTunnelingRoute(initialLocation)) {
                yield put(push('/sign-up/confirm'));
            } else {
                yield put(push(initialLocation));
            }
        } else {
                yield put(push(initialLocation));
                yield put(setUserPath({initialLocation: defaultLocation}))
        }
    } catch (e) {
        console.log(e);
        yield put(authError(e.code));
        if (e.code === "UserNotConfirmedException") {
            // means that user did not confirm their email via confirmation code so they need to be redirect on proper page
            yield put(signUpSuccess());
            if (isTunnelingRoute(initialLocation)) {
                yield put(push(initialLocation));
                yield put(setUserPath({initialLocation: defaultLocation}))
            } else {
                yield put(push('/sign-up/confirm'));
            }
        }
    }
}

function* signUpSaga() {
    const data = yield select(selectFormInputs);
    const initialLocation = yield select(selectInitialLocation);
    try {
        const { user } = yield call([Auth, Auth.signUp], {
            username: data.email,
            password: data.password,
            attributes: {
                email: data.email,
            }
        });
        yield put(resetTunnel());
        yield put(signUpSuccess(user));
        if (isTunnelingRoute(initialLocation)) {
            yield put(push(initialLocation));
            yield put(setUserPath({initialLocation: defaultLocation}));
        } else {
            yield put(push('/sign-up/confirm'))
        }
    } catch (e) {
        console.log({e});
        yield put(authError(e.code))
    }
}

function* confirmSignUpSaga() {
    const data = yield select(selectFormInputs);
    const user = yield select(selectUser);
    try {
        yield call([Auth, Auth.confirmSignUp], (user && user.attributes && user.attributes.email) || data.email, data.verificationCode);
        const currentUser = yield call([Auth, Auth.signIn], data.email, data.password, {email: data.email});
        yield put(signInSuccess({currentUser}));
        yield put(nextActiveStep())
    } catch (e) {
        console.log({e});
        yield put(authError(e.code));
    }
}

function* resendConfirmationSaga() {
    const data = yield select(selectFormInputs);
    try {
        yield call([Auth, Auth.resendSignUp], data.email);
    } catch (e) {
        console.log({e});
        yield put(authError(e.message));
    }
}

function* requestConfirmationCodeSMSSaga() {
    const user = yield select(selectUser);
    const data = yield select(selectFormInputs);
    try {
        if (data.phone && data.phone !== "") {
            const resp = yield call(getOrPostToAuthenticatedRoute, user, '/users', true, {phone: data.phone});
            if (resp.status !== 200) {
                yield call(dispatchErrorAccordingToStatus, resp.status, "error.update.phone", authError)
                return
            } else {
                yield put(updateSingleUserField({field: "phone", value: data.phone}))
            }
        }
        const resp = yield call(getOrPostToAuthenticatedRoute, user, "/tel/update", true);
        if (resp.status === 200) {
            yield put(requestConfirmationCodeSMSSuccess());
        } else if (resp.status === 403 || resp.status === 500) {
            const errorData = yield resp.json();
            yield put(authError(errorData.messageCode !== "" ? errorData.messageCode : "error.sms.send"))
        } else {
            yield put(authError("error.sms.send"));
        }
    } catch (e) {
        console.log({e});
        yield put(authError(e));
    }
}

function* requestConfirmPhoneSaga(action) {
    const user = yield select(selectUser);
    const data = yield select(selectFormInputs);
    try {
        const resp = yield call(getOrPostToAuthenticatedRoute, user, `/tel/code?codeSms=${data.verificationCode}`, true);
        if (resp.status === 200) {
            yield put(confirmPhoneSuccess());
            yield put(stepSucceeded());
        } else {
            yield put(authError("error.phone.confirmed"))
        }
    } catch (e) {
        console.log({e});
        yield put(authError(e));
    }
}

function* saveProfileSaga(action) {
    const user = yield select(selectUser);
    const fingerprint = yield select(selectFingerprint);
    const data = {...yield select(selectFormInputs), fingerprint};
    try {
        const resp = yield call(getOrPostToAuthenticatedRoute, user, '/users', true, data);
        if (resp.status !== 200) {
            console.log(resp);
            yield put(authError(resp.statusText))
        } else {
            // refresh user to make sure their attributes are up to date
            const newUser = yield call([Auth, Auth.currentAuthenticatedUser], {bypassCache: true});
            refreshSessionAndSetCookies(user);
            yield put(saveProfileSuccess(newUser));
            const resp = yield call(getOrPostToAuthenticatedRoute, newUser, "/users");
            if (resp.status !== 200) {
                yield put(authError(resp.statusText))
            } else {
                const data = yield resp.json();
                yield put(updateUserProfile(data));
            }
            if (action.payload) {
                yield put(nextActiveStep())
            }
        }
    } catch (e) {
        console.log({e});
        yield put(authError(e.message))
    }
}

function* forgotPasswordSaga() {
    const data = yield select(selectFormInputs);
    try {
        yield call([Auth, Auth.forgotPassword], data.email, {email: data.email});
    } catch (e) {
        console.log({e});
        yield put(authError(e.message))
    }
}

function* confirmForgotPasswordSaga() {
    const data = yield select(selectFormInputs);
    try {
        yield call(
            [Auth, Auth.forgotPasswordSubmit],
            data.email,
            data.verificationCode,
            data.password,
            {email: data.email}
            );
        yield put(confirmForgotPasswordSuccess());
        yield put(push('/login'));
    } catch (e) {
        console.log({e});
        yield put(authError(e.message))
    }
}

function* changePasswordSaga() {
    const user = yield select(selectUser);
    const data = yield select(selectFormInputs);
    try {
        yield call([Auth, Auth.changePassword], user, data.password, data.newPassword);
        yield put(changePasswordSuccess());
    } catch (e) {
        console.log({e});
        yield put(authError(e.message))
    }
}

function* signOutSaga() {
    try {
        yield put(restoreDefault());
        yield put(resetTunnel());
        yield call([Auth, Auth.signOut]);
        setCookie("", "id", 0);
        setCookie("", "access", 0);
        setCookie("", "refresh", 0);
        yield put(push('/login'));
    } catch (e) {
        console.log({e});
        yield put(authError(e.message))
    }
}

const setCookie = (token, tokenType, maxAge) => {
    document.cookie = `aws.auth.${tokenType}=${token}; domain=${config.domain}; max-age=${maxAge}; path=/; SameSite=Lax; Secure`;
};

const getCookie = (tokenType) => {
    return document.cookie.search(`aws.auth.${tokenType}`) !== -1;
};

function* continueAsClickSaga() {
    const user = yield select(selectUser);
    const redirUri = yield select(selectRedirUri);
    const initialLocation = yield select(selectInitialLocation);
    try {
        refreshSessionAndSetCookies(user);
        if (!isUserValid(user) && !isTunnelingRoute(initialLocation)) {
            yield put(push('/sign-up/confirm'));
        } else if (initialLocation !== defaultLocation) {
            yield put(push(initialLocation));
            yield put(setUserPath({initialLocation: defaultLocation}));
        } else {
            yield put(setLoading());
            yield put(resetUserPath());
            refreshSessionAndSetCookies(user, true, redirUri)
        }
    } catch (e) {
        console.log({e});
        yield put(authError(e.message))
    }
}

const refreshSessionAndSetCookies = (user, redirect=false, redirUri="") => {
    //we only set refresh to make sure user gets logged in DD
    const session = user.getSignInUserSession();
    user.refreshSession(session.getRefreshToken(), (err, session) => {
        setCookie("", "id", 0);
        setCookie("", "access", 0);
        setCookie(session.getRefreshToken().getToken(), "refresh", 2500000);
        if (redirect) window.location.replace( `http${process.env.NODE_ENV === 'production' ? 's' : ''}://${config.isRecette ? 'recette.' : ''}${config.domain}${redirUri || ''}`);
    })
};

function* getSumSubTokenSaga(action) {
    const user = yield select(selectUser);
    const { level, saleId } = action.payload;
    try {
        const resp = yield call(getOrPostToAuthenticatedRoute, user, `/sumsub/token?level=${level}${saleId ? "&saleId=" + saleId: ""}`);
        const data = yield resp.json();
        yield put(requestSumSubTokenSuccess(data.token))
    } catch (e) {
        console.error({e});
        yield put(authError(e));
    }
}

function* updateUserApplicantSaga(action) {
    const user = yield select(selectUser);
    try {
        yield call(getOrPostToAuthenticatedRoute, user, '/users', true, {applicantId: action.payload.applicantId});
        // failure is not a major issue
    } catch (e) {
        console.error({e});
        yield put(authError(e));
    }
}

function* updateFingerprintSaga() {
    try {
        let fingerprint = "";
        const auth2Token = document.cookie.split(';').find(c => c.includes(config.uuidCookieName));
        if (auth2Token) {
            fingerprint = auth2Token.split('=')[1];
        }
        yield put(updateFingerprintSuccess(fingerprint));
    } catch (e) {
        console.error({e});
        yield put(authError(e));
    }
}

function* watchAuthSaga() {
    yield takeLatest(signInRequest, signInSaga);
    yield takeLatest(signInSuccess, loadProfileSaga);
    yield takeLatest(refreshUserProfile, loadProfileSaga);
    yield takeLatest(signUpRequest, signUpSaga);
    yield takeLatest(confirmSignUpRequest, confirmSignUpSaga);
    yield takeLatest(resendConfirmationMail, resendConfirmationSaga);
    yield throttle(15000, requestConfirmationCodeSMS, requestConfirmationCodeSMSSaga);
    yield takeLatest(confirmPhoneRequest, requestConfirmPhoneSaga);
    yield takeLatest(saveProfileRequest, saveProfileSaga);
    yield takeLatest(forgotPasswordRequest, forgotPasswordSaga);
    yield takeLatest(confirmForgotPasswordRequest, confirmForgotPasswordSaga);
    yield takeLatest(changePasswordRequest, changePasswordSaga);
    yield takeLatest(requestSumSubToken, getSumSubTokenSaga);
    yield takeLatest(updateUserApplicantIdRequest, updateUserApplicantSaga);
    yield takeLatest(continueAsClick, continueAsClickSaga);
    yield takeLatest(signOut, signOutSaga);
    yield takeLatest(REHYDRATE, initSaga);
    yield takeLatest(updateFingerprintRequest, updateFingerprintSaga);
}

export default watchAuthSaga;