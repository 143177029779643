import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import config from "../../config";

const drouotCat = [
    {uri:"/1050/beaux-arts", id:"beaux.arts"},
    {uri:"/1051/mobilier--objets-d-art", id:"mobilier.objet.art"},
    {uri:"/1052/Collections", id:"collections"},
    {uri:"/1053/luxe--art-de-vivre", id:"luxe.artvivre"},
    {uri:"/43/arts-d-asie", id:"art.asie"},
    {uri:"/1055/arts-du-monde", id:"art.dumonde"},
];

const moniteurCat = [
    {uri:"/250/Véhicules", id:"vehicules"},
    {uri:"/255/Matériel professionnel", id:"professional.material"},
    {uri:"/251/Maison", id:"house"},
    {uri:"/252/Loisirs", id:"hobby"},
    {uri:"/253/Mode", id:"fashion"},
    {uri:"/254/Multimédia", id:"multimedia"},
]
const MenuCategory = (props) => {
    const intl = useIntl()
    const domain = `https://${config.domain}/${intl.locale}`;
    const categories = config.isMoniteur ? moniteurCat : drouotCat;
    return (
        <>
            {categories.map((cat) =>
                <a href={`${domain}/c${cat.uri}?lotGroupTheme=all`} key={cat.id}>
                    <span className="uppercase mr-2"><FormattedMessage id={`label.cat.${cat.id}`}/></span>
                </a>
            )}
        </>
    )
};

export default MenuCategory;