import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import {
    selectUser,
    selectCurrentUserProfile,
    refreshUserProfile,
    changePasswordRequest,
    saveProfileRequest,
    signOut,
    selectIsFederatedUser,
    resetFormInputs,
    continueAsClick, selectFormInputs,
} from "../reducers/authSlice";
import ProfileNav from "../components/profile/ProfileNav";
import {FormattedMessage} from "react-intl";
import ProfileNavMobile from "../components/profile/ProfileNavMobile";
import Footer from "../components/global/Footer";
import Header from "../components/global/Header";
import ProfileAccount from "../components/profile/ProfileAccount";
import ProfileAddress from "../components/profile/ProfileAddress";
import ProfilePreference from "../components/profile/ProfilPreference";
import ProfileBank from "../components/profile/ProfileBank";
import ProfileAddressDeliv from "../components/profile/ProfileAddressDeliv";

export const tabEnum = {
    info: "info",
    account: "account",
    cards: "cards",
    preference: "preference",
};

const ProfileEditContainer = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const currentUserProfile = useSelector(selectCurrentUserProfile);
    const isFederatedUser = useSelector(selectIsFederatedUser);
    const formInputs = useSelector(selectFormInputs);

    // TODO include fields validation before passiong to updateForm action
    const switchTab = (tab) => {
        dispatch(push(`/profile/${tab}`));
    };

    useEffect(() => {
        dispatch(resetFormInputs());
    }, [props.tab]);

    useEffect(() => {
        dispatch(refreshUserProfile());
    }, []);

    return (
        <>
            <Header signOut={() => dispatch(signOut())}/>
            <div>
                <span className="best-title"><FormattedMessage id="mypass.information.profil"/></span>
                <div className="title-divider">
                    <span></span>
                </div>
            </div>
            <div className="clear"/>

            <div className="flex flex-row flex-auto flex-shrink-0 antialiased text-gray-800 mt-4 mb-4">
                <div className="hidden lg:block lg:w-1/4 overflow-hidden">
                    <div className="overflow-y-auto overflow-x-hidden flex-grow">
                        <ProfileNav
                            switchTab={switchTab}
                            currentTab={props.tab}
                            signOut={() => dispatch(signOut())}
                        />
                    </div>


                </div>
                <div className="w-full lg:w-3/4 bg-white lg:rounded-tr-lg lg:rounded-br-lg">
                    <div className="2xl:hidden xl:hidden lg:hidden">
                        <ProfileNavMobile
                            switchTab={switchTab}
                            currentTab={props.tab}
                            signOut={() => dispatch(signOut())}
                        />
                    </div>

                    {props.tab === tabEnum.account && <div className="bg-white rounded-lg shadow-lg p-4 mb-6">
                        <ProfileAccount
                            onChange={props.updateForm}
                            email={user.attributes.email}
                            isValidUser={user.attributes["custom:pass_id"] && user.attributes["custom:pass_id"] !== 0}
                            isFederatedUser={isFederatedUser}
                            currentUserProfile={currentUserProfile}
                            changePassword={() => dispatch(changePasswordRequest())}
                            refreshUserProfile={() => dispatch(refreshUserProfile())}
                        />
                    </div>}

                    {props.tab === tabEnum.info && <div className="bg-white rounded-lg shadow-lg p-4 mb-6">
                        <ProfileAddress
                            onChange={props.updateForm}
                            email={user.attributes.email}
                            isValidUser={user.attributes["custom:pass_id"] && user.attributes["custom:pass_id"] !== 0}
                            isFederatedUser={isFederatedUser}
                            currentUserProfile={currentUserProfile}
                            saveProfile={() => dispatch(saveProfileRequest())}
                            refreshUserProfile={() => dispatch(refreshUserProfile())}
                        />
                        <br /><br />

                        <ProfileAddressDeliv
                            onChange={props.updateForm}
                            formInputsAddress={formInputs.address}
                            formInputsAdditionalAddress={formInputs.additionalAddress}
                            formInputsZipCode={formInputs.zipCode}
                            formInputsCountry={formInputs.country}
                            formInputsCity={formInputs.city}
                            formInputsState={formInputs.state}
                            currentUserProfile={currentUserProfile}
                        />

                    </div>}

                    {props.tab === tabEnum.cards && <div className="bg-white rounded-lg shadow-lg p-4 mb-6">
                        <ProfileBank
                            cards={currentUserProfile.creditCards}
                            refreshUserProfile={() => dispatch(refreshUserProfile())}
                        />
                    </div>}

                    {props.tab === tabEnum.preference && <div className="bg-white rounded-lg shadow-lg p-4 mb-6">

                        <ProfilePreference
                            onChange={props.updateForm}
                            currentUserProfile={currentUserProfile}
                        />
                    </div>}

                    {[tabEnum.info, tabEnum.preference].includes(props.tab) && <button onClick={() => dispatch(saveProfileRequest())} className="bg-blue-700 text-white px-6 py-2 rounded font-medium hover:bg-blue-800 transition duration-200 each-in-out mt-4" >
                        <FormattedMessage id="common.save.profil"/>
                    </button>}
                    <button onClick={() => dispatch(continueAsClick())} className="bg-white border text-gray-700 px-6 py-2 rounded hover:bg-gray-200 transition duration-200 each-in-out ml-2 mt-6">
                        <FormattedMessage id="label.back"/>
                    </button>

                </div>
            </div>
            <Footer/>
        </>
    )
};

ProfileEditContainer.propTypes = {
    updateForm: PropTypes.func,
    tab: PropTypes.string
};

export default ProfileEditContainer;