import {Auth} from "aws-amplify";
import {FormattedMessage} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faApple, faGoogle} from "@fortawesome/free-brands-svg-icons";
import React from "react";

const SocialLogin = () => {
    return (
        <div className="rounded-t mb-0 py-6">
            <div className="text-center mb-3">
                <h6 className="text-blueGray-500 text-sm font-bold"><FormattedMessage id="common.sign.in.with"/></h6>
            </div>
            <div className="btn-wrapper text-center">
                {/*<button onClick={() => Auth.federatedSignIn({provider: 'Google'})}*/}
                {/*        className="bg-gray-700 text-white my-2 w-5/6 sm:w-2/6 px-6 py-2 rounded font-medium mx-3 hover:bg-gray-800 transition duration-200 each-in-out"*/}
                {/*        type="button">*/}
                {/*    <FontAwesomeIcon icon={faGoogle} className="mr-2" />*/}
                {/*    <FormattedMessage id="label.google"/>*/}
                {/*</button>*/}
                <button onClick={() => Auth.federatedSignIn({provider: 'SignInWithApple'})}
                        className="bg-gray-700 text-white my-2 w-5/6 sm:w-2/6 px-6 py-2 rounded font-medium mx-3 hover:bg-gray-800 transition duration-200 each-in-out"
                        type="button">
                    <FontAwesomeIcon icon={faApple} className="mr-2" />
                    <FormattedMessage id="label.apple"/>
                </button>
            </div>
            <hr className="mt-6 border-b-1 border-blueGray-300"/>
        </div>
    )
}

export default SocialLogin;