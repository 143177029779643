const isMoniteur = false;
const isRecette = true;

export default {
  isMoniteur,
  isRecette,
  site: isMoniteur ? "moniteur" : "drouot",
  domain: 'localhost',
  gingolemApi: 'http://localhost:8081',
  //gingolemApi: `https://${isRecette ? 'internal-recette.zonesecure.org' : 'api.drouot.com'}/${
  //   isMoniteur ? 'moniteur' : 'drouot'
  //}/gingolem`,
  // gringottsApi: "http://localhost:8000",
  gringottsApi: `https://${isRecette ? 'internal-recette.zonesecure.org' : 'api.drouot.com'}/payment`,
  drouotPspid: isRecette ? "DROUOTFLEXTEST" : "drouotsi",
  oauthRedirect: `http://localhost:3000`,
  sumsubUrl: "https://api.sumsub.com",
  uuidCookieName: "auth2_token",
  gaTagIdDrouot: "G-EVJGN3MMQP",
  gaTagIdMoniteur: "G-5F5MEBJ705",
  gtmTagIdDrouot: "",
  gtmTagIdMoniteur: "",
};