import {useEffect} from 'react';
import PropTypes from "prop-types";

import snsWebSdk from '@sumsub/websdk'
import config from "../config";
import {useIntl} from "react-intl";

const SumSub = (props) => {

    const intl = useIntl()
    useEffect(() => {
        const snsWebSdkInit = snsWebSdk.init(props.token, () => {
            // EXPIRATION HANDLER
            /* generate a new token and launch WebSDK again */
            props.requestSumSubToken()
        });

        const snsWebSdkInstance = snsWebSdkInit.withConf({
                lang: intl.locale,
                email: props.email,
                phone: `+${props.userProfile.phone}`,
                firstName: props.userProfile.firstName,
                lastName: props.userProfile.lastName,
                onMessage: (type, payload) => {
                    console.log('WebSDK onMessage', type, payload);
                    switch (type) {
                        case "idCheck.onApplicantLoaded":
                            props.updateUserApplicantIdRequest(payload.applicantId);
                            break;
                        case "idCheck.onApplicantSubmitted":
                        case "idCheck.onApplicantResubmitted":
                            if (props.isTunnel) props.setCanValidateStep(true);
                            break;
                        case "idCheck.applicantStatus":
                            if (payload.reviewResult && payload.reviewResult.reviewAnswer && payload.reviewResult.reviewAnswer === "GREEN") {
                                if (props.isTunnel) props.setCanValidateStep(true);
                            }
                            break;
                        default:
                    }
                },
                onError: (error) => {
                    console.log('WebSDK onError', error)
                },
            }).build();

        snsWebSdkInstance.launch('#sumsub-websdk-container');
    }, [props.token]);


    return (
        <div id="sumsub-websdk-container" className="w-full"></div>
)};

SumSub.propTypes = {
    email: PropTypes.string,
    userProfile: PropTypes.object,
    token: PropTypes.string,
    isTunnel: PropTypes.string,
    requestSumSubToken: PropTypes.func,
    updateUserApplicantIdRequest: PropTypes.func,
};

export default SumSub;