import React, {useState} from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import {TextField} from "@material-ui/core";
import CenteredModal from "../tools/CenteredModal";
import DocumentSubmitContainer from "../../containers/DocumentSubmitContainer";
import SumSubContainer from "../../containers/SumSubContainer";
import {AWAITING, PENDING} from "../../structs/apiConstants";
import {hasValidPersonalId, hasAwaitingDocs} from "../../Passeport";
import ProfileData from "./ProfileData";
import Tooltip from '@material-ui/core/Tooltip';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";



const DOC = 0;
const SUMSUB = 1;
const PWD = 2;

const ProfileAccount = (props) => {
    let dateDay = props.currentUserProfile.birthDate.getDate();
    let dateMonth = props.currentUserProfile.birthDate.getMonth() + 1;
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState(0);

    if (dateMonth < 10) {
        dateMonth = `0${dateMonth}`;
    }
    if (dateDay < 10) {
        dateDay = `0${dateDay}`;
    }
    const birthdayMyFormat = dateDay + '/' + dateMonth + '/' + props.currentUserProfile.birthDate.getFullYear();

    const modifyPasswordModal =
        <div className="w-full sm:w-4/6 flex flex-col">
            <h5 className="text-2xl mb-3"><FormattedMessage id="label.change.your.pwd"/></h5>
            <div className="flex-initial my-3 w-full">
                <span className="text-sm"><FormattedMessage id="label.password.rule"/></span>
            </div>
            <div className="flex-initial my-3 w-full">
                <TextField key="" type="password" label={<FormattedMessage id="label.current.mdp"/>} variant="filled" size="medium" name="password" onChange={props.onChange} defaultValue=""/>
            </div>
            <div className="flex-initial my-3 w-full">
                <TextField key="" type="password" label={<FormattedMessage id="label.new.mdp"/>} variant="filled" size="medium" name="newPassword" onChange={props.onChange} defaultValue=""/>
            </div>
            <div className="flex-initial mt-2">
                <button onClick={() => {props.changePassword(); setOpenModal(false)}}
                        className="underline text-blue-500">
                    <FormattedMessage id="label.confirm"/>
                </button>
            </div>
        </div>;

    const openModalWithType = (type) => {
        setModalType(type);
        setOpenModal(true);
    };

    const closeModal = () => {
        if (modalType === SUMSUB) props.refreshUserProfile();
        setOpenModal(false)
    };

    return (
        <>
            <CenteredModal openModal={openModal} onCloseModal={closeModal}>
                {modalType === DOC && <DocumentSubmitContainer onSaveAction={closeModal}/>}
                {modalType === SUMSUB && <SumSubContainer />}
                {modalType === PWD && modifyPasswordModal}
            </CenteredModal>
            <h2 className="mt-2 text-lg dark:text-gray-300 font-extrabold capitalize">
                {props.currentUserProfile.lastName} {props.currentUserProfile.firstName}
                <span className="ml-1 text-sm"> - {props.currentUserProfile.professional ? props.currentUserProfile.company : birthdayMyFormat}</span>
            </h2>
            <div>

                {props.currentUserProfile.siret !== "" && <ProfileData label="label.siret" value={props.currentUserProfile.siret} />}

                {props.currentUserProfile.interVAT !== '' && <ProfileData label="label.interVAT" value={props.currentUserProfile.interVAT} />}

                <ProfileData label="label.identifiant" value={props.currentUserProfile.userId} />

                <ProfileData label="label.email" value={props.email} />

                {!props.isFederatedUser && <ProfileData label="common.password" value={
                    <button onClick={() => openModalWithType(PWD)} className="text-blue-500">
                        <FormattedMessage id="label.modifier"/>
                    </button>
                }/>}

                <ProfileData label="label.identity" value={
                    hasValidPersonalId(props.currentUserProfile) ?
                        <span className="underline"><FormattedMessage id="label.verified" /></span>
                        : props.currentUserProfile.idCheckStatus === PENDING ?
                        <span><FormattedMessage id="label.pending" /></span> :
                        <button onClick={() => openModalWithType(SUMSUB)} className="text-blue-500">
                            <FormattedMessage id="label.add.id.doc"/>
                        </button>
                } />

                {props.currentUserProfile.professional &&
                <>
                    <ProfileData label="label.document" value={
                        !hasAwaitingDocs(props.currentUserProfile) &&
                        <button onClick={() => openModalWithType(DOC)} className="text-blue-500">
                            <FormattedMessage id="label.add"/>
                        </button>
                    } />
                    <br />
                    {props.currentUserProfile.documents.map(doc =>
                        <div className="text-sm">
                            <div className="inline-block mt-2 w-2/6 pl-2 border-l-2 border-gray-600 overflow-hidden overflow-ellipsis">
                                {/*todo si pays KBIS label kbis: */}
                                <label><FormattedMessage id={`label.${doc.type}`}/> : {doc.name}</label>
                            </div>
                            <div className="inline-block mt-2 w-7/12 text-right sm:text-left">
                                <span><FormattedMessage id={`label.doc.${doc.status}`}/>
                                    {doc.status === AWAITING && <Tooltip title={<FormattedMessage id="label.pending.pro.doc.tip" />}><span className="pl-2"><FontAwesomeIcon color="grey" icon={faQuestionCircle} /></span></Tooltip>}
                                </span>
                            </div>
                        </div>
                    )}
                </>
                }
            </div>
        </>
    )
};

ProfileAccount.propTypes = {
    onChange: PropTypes.func,
    saveProfile: PropTypes.func,
    changePassword: PropTypes.func,
    backToSignedIn: PropTypes.func,
    uploadDocuments: PropTypes.func,
    confirmPhone: PropTypes.func,
    signOut: PropTypes.func,
    email: PropTypes.string,
    isValidUser: PropTypes.bool,
    isFederatedUser: PropTypes.bool,
    currentUserProfile: PropTypes.object,
};

export default ProfileAccount;