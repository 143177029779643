import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {confirmForgotPasswordRequest, forgotPasswordRequest} from "../reducers/authSlice";
import ForgotPassword from "../components/ForgotPassword";
import HeaderLight from "../components/global/HeaderLight";

const ForgotPasswordContainer = (props) => {
    const dispatch = useDispatch();
    return (
        <>
            <HeaderLight/>
            <ForgotPassword
                onChange={props.updateForm}
                forgotPasswordRequest={() => dispatch(forgotPasswordRequest())}
                confirmForgotPasswordRequest={() => dispatch(confirmForgotPasswordRequest())}
            />
        </>
    )
};

ForgotPasswordContainer.propTypes = {
    updateForm: PropTypes.func,
};

export default ForgotPasswordContainer;