import { Switch, Route, Redirect } from 'react-router-dom';// react-router v5
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    AmplifyState,
    updateFormInputs,
    selectAmplifyState,
} from "./reducers/authSlice";
import SignUpFormContainer from "./containers/SignUpFormContainer";
import ProfileEditContainer, {tabEnum} from "./containers/ProfileEditContainer";
import ForgotPasswordContainer from "./containers/ForgotPasswordContainer";
import SignInFormContainer from "./containers/SignInFormContainer";
import SignedInPageContainer from "./containers/SignedInPageContainer";
import MessageContainer from "./containers/MessageContainer";
import RegistrationContainer from "./containers/RegistrationContainer";
import TunnelContainer from "./containers/TunnelContainer";
import LoadingContainer from "./containers/LoadingContainer";
import config from "./config";
import CgvContainer from "./containers/cgvContainer";
import { updateFingerprintRequest } from "./reducers/authSlice";
import WaitingPaymentContainer from "./containers/WaitingPaymentContainer";

const App = () =>  {
    /* Create the form state and form input state */
    const amplifyState = useSelector(selectAmplifyState);
    const dispatch = useDispatch();

    /* Save the fingerprint into store */
    useEffect(() => {
        dispatch(updateFingerprintRequest());
    }, [])

    /* onChange handler for form inputs */
    function updateForm(e) {
        const {name, value, type, checked} = e.target;
        dispatch(updateFormInputs({
            name: name,
            value: type === 'checkbox' ? checked : value.trim() ,
        }))
    }

    const urlMapping = {
        '/': {state: [AmplifyState.SignedIn], component: <SignedInPageContainer/>, redirect: '/login'},
        '/login': {state: [AmplifyState.SignedOut, AmplifyState.SignUp], component: <SignInFormContainer updateForm={updateForm} />, redirect: '/'},
        '/sign-up': {state: [AmplifyState.SignedOut, AmplifyState.SignUp], component: <SignUpFormContainer updateForm={updateForm} />, redirect: '/'},
        '/sign-up/confirm': {state: [AmplifyState.SignUp, AmplifyState.SignedIn], component: <TunnelContainer updateForm={updateForm}/>, redirect: '/'},
        '/forgot-password': {state: [AmplifyState.SignedOut], component: <ForgotPasswordContainer updateForm={updateForm}/>, redirect: '/'},
        '/profile/account': {state: [AmplifyState.SignedIn], component: <ProfileEditContainer updateForm={updateForm} tab={tabEnum.account}/>, redirect: '/login'},
        '/profile/info': {state: [AmplifyState.SignedIn], component: <ProfileEditContainer updateForm={updateForm} tab={tabEnum.info}/>, redirect: '/login'},
        '/profile/cards': {state: [AmplifyState.SignedIn], component: <ProfileEditContainer updateForm={updateForm} tab={tabEnum.cards}/>, redirect: '/login'},
        '/profile/preference': {state: [AmplifyState.SignedIn], component: <ProfileEditContainer updateForm={updateForm} tab={tabEnum.preference}/>, redirect: '/login'},
        '/registration/:saleId': {state: [AmplifyState.SignUp, AmplifyState.SignedIn], component: <RegistrationContainer updateForm={updateForm}/>, redirect: '/login'},
        '/app-friendly/registration/:saleId': {state: [AmplifyState.SignUp, AmplifyState.SignedIn], component: <RegistrationContainer updateForm={updateForm}/>, redirect: '/login'},
        '/cgv/:saleId': {state: [AmplifyState.SignUp, AmplifyState.SignedIn], component: <CgvContainer updateForm={updateForm}/>, redirect: '/login'},
        '/tunnel': {state: [AmplifyState.SignedOut], component: <TunnelContainer updateForm={updateForm}/>, redirect: '/'},
        '/waiting-payment': {state: [AmplifyState.SignedOut, AmplifyState.SignedIn], component: <WaitingPaymentContainer/>, redirect: '/'},
    };

    return (
        <div className={`${config.site} container mx-auto h-full my-3`}>
            <div className="w-full ml-auto mr-auto px-1 sm:px-5 2xl:px-40">
                <LoadingContainer/>
                <MessageContainer/>
                <Switch>
                    {Object.keys(urlMapping).map(path =>
                        <Route exact path={path} key={path}>{
                            urlMapping[path].state.includes(amplifyState) ?
                                urlMapping[path].component :
                                <Redirect to={urlMapping[path].redirect}/>
                        }</Route>
                    )}
                </Switch>
            </div>
        </div>
    )
};

export default App;
