import {call, put} from "redux-saga/effects";
import config from "../config";

export function *getOrPostToAuthenticatedRoute(authenticatedUser, route, post=false, newData={}) {
    // todo force refresh to avoid 401 ?
    const session = authenticatedUser.getSignInUserSession();
    const requestConfig = {
        method: post ? "POST" : "GET",
        headers: {
            "Authorization": `Bearer ${session.getIdToken().getJwtToken()}`
        },
    };
    if (post) {
        requestConfig.body= JSON.stringify(newData)
    }
    return yield call(fetch, config.gingolemApi + route, requestConfig);
}

export function *makeGringottsAuthenticatedCallToRoute(authenticatedUser, route, post = false, data = {}) {
    const session = authenticatedUser.getSignInUserSession();
    const request = {
        method: post ? "POST" : "GET",
        headers: {
            "Authorization": `Bearer ${session.getIdToken().getJwtToken()}`,
        },
    };
    if (post) {
        request.headers.Accept = "application/json";
        request.headers["Content-Type"] = "application/json;charset=UTF-8";
    }
    if (post) {
        request.body = JSON.stringify(data);
    }
    return yield call(fetch, config.gringottsApi + route, request)
}

export function *dispatchErrorAccordingToStatus(status, errorCode, errorAction) {
    yield put(errorAction(status === 401 ? "Unauthorized" : errorCode));

}