import { call, put, takeLatest, select } from 'redux-saga/effects';
import {dispatchErrorAccordingToStatus, getOrPostToAuthenticatedRoute} from "./helper";
import {selectUser} from "../reducers/authSlice";
import {
    getRegistrationForSaleRequest,
    getRegistrationForSaleSuccess,
    createRegistrationForSaleRequest,
    createRegistrationForSaleSuccess,
    registrationError,
} from "../reducers/registrationSlice";
import { push } from 'connected-react-router';
import config from "../config";
import {stepSucceeded} from "../reducers/tunnelSlice";


function *getRegistrationForSaleSaga(action) {
    const user = yield select(selectUser);
    //todo get CGV
    try {
        const successData = {id: action.payload};
        const saleData = yield call(fetch, `${config.gingolemApi}/sale/${action.payload}`);
        if (saleData.status !== 200) {
            yield call(dispatchErrorAccordingToStatus, saleData.status, "error.sale.data", registrationError);
            return
        }
        successData.sale = (yield saleData.json()).sale;
        const restriction = yield call(fetch,`${config.gingolemApi}/sales/${action.payload}/restrictions`);
        if (restriction.status === 200) {
            successData.restrictions = yield restriction.json();
        } else {
            yield call(dispatchErrorAccordingToStatus, restriction.status, "error.sale.data", registrationError);
            return
        }
        if (user && user.attributes["custom:pass_id"]) {
            const resp = yield call(getOrPostToAuthenticatedRoute, user, `/registrations/${action.payload}`);
            if (resp.status === 200 || resp.status === 404) {
                successData.registration = resp.status === 200 ? yield resp.json() : null;
            } else {
                yield call(dispatchErrorAccordingToStatus, resp.status, "error.registration.data", registrationError);
                return
            }
        }
        yield put(getRegistrationForSaleSuccess(successData));
    } catch (e) {
        console.error({e});
        yield put(registrationError(e));
    }
}

function *createRegistrationForSaleSaga(action) {
    const user = yield select(selectUser);
    try {
        const resp = yield call(getOrPostToAuthenticatedRoute, user, `/registrations/${action.payload}/create`, true, {});
        if (resp.status === 200) {
            const registration = yield resp.json();
            if (!registration.error) {
                // when registration is auto validated we can skip optional next step (example vib pro user) so we pass registration object immediately
                yield put(createRegistrationForSaleSuccess(registration.status === 2 ? registration : null));
                yield put(stepSucceeded());
            } else {
                yield put(registrationError(registration.error))
                yield put(push("/"))
            }
        } else {
            yield call(dispatchErrorAccordingToStatus, resp.status, "error.registration.create", registrationError);
        }
    } catch (e) {
        console.error(e)
    }
}

function* watchRegistrationSaga() {
    yield takeLatest(getRegistrationForSaleRequest, getRegistrationForSaleSaga);
    yield takeLatest(createRegistrationForSaleRequest, createRegistrationForSaleSaga);
}

export default watchRegistrationSaga;